.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.modal-section {
  position: relative;
  min-width: 280px;
  max-width: 296px;
  min-height: 300px;
  border-radius: 18px;
  background-color: #f8fafe;
  z-index: 999;
  margin: auto;
  margin-top: 140px;
  padding: 24px 10px;
  overflow: hidden;
}

.modal-dimmed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}

.info-modal-wrapper {
  margin-top: 20px;
}

.info-modal-wrapper img {
  margin-top: 24px;
}

.info-modal-text {
  margin: 30px 14px 0 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 144px;
  font-size: 14px;
  gap: 10px;
  text-align: center;
}

.info-modal-text li {
  margin-bottom: 16px;
}

.info-modal-text li span {
  font-size: 12px;
  font-weight: 600;
}
